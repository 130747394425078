import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Caret from "../../assets/icons/caret.png";
import {updateText} from "../../store/actions/Editor";
import {useDispatch, useSelector} from "react-redux";

const Font = ({ name, styles, onClick }) => {
  const dispatch = useDispatch();
  const {selectedItemId, draw} = useSelector(state => state.editor)
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(styles[0].name);
  const handleSelect = (event, name) => {
    event.stopPropagation();
    setSelectedStyle(name);
    setIsOpen(false);
  };


  const handleChangeFont = (font) => {
    let item = getSelectedItem()
    if (item) {
      item.data.font.weight = font;
      dispatch(updateText({item: item.data, selectedId: selectedItemId}))
    }
  }


  const getSelectedItem = () => {
    const texts = draw.card.texts.filter((text) => text.id === selectedItemId);
    if (texts.length > 0) return {type: "texts", data: texts[0]};
    return null;
  };


  return (
    <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <div className="w-full h-20 relative flex flex-col items-center justify-center cursor-pointer border border-white hover:border-black group" onClick={onClick}>
        <span className="font-medium text-lg" style={{ fontFamily: name }}>
          {name}
        </span>
        {styles.length > 1 && (
          <div
            className="w-full flex flex-col items-center mt-2 px-4"
            onClick={() => setIsOpen(true)}
          >
            <div className="flex justify-center items-center relative">
              <span className="text-xs text-gray-500">{selectedStyle}</span>
              <img
                src={Caret}
                className="w-3 absolute -right-2 translate-x-full transition opacity-0 group-hover:opacity-100"
              />
            </div>
            {isOpen && (
              <ul className="absolute z-10 left-3 -bottom-0.5 right-3 translate-y-full bg-white shadow">
                {styles.map((style) => (
                  <li
                    key={style.name}
                    className={`w-full h-8 flex justify-center items-center ${
                      selectedStyle === style.name ? `bg-gray-200` : ``
                    }`}
                    onClick={(e) => {
                      handleSelect(e, style.name)
                      handleChangeFont(style.fontWeightNum)
                    }}
                  >
                    {style.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Font;
