import React, {Fragment, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedItemId,
  setSelectedTool,
  updateDesign,
} from "../store/actions/Editor";
import { FONTS, INIT_TEXT } from "../constants";
import Image from "../assets/icons/backdrop.svg";
import BackActive from "../assets/icons/backdrop-hover.svg";
import Sticker from "../assets/icons/sticker.svg";
import StickerActive from "../assets/icons/sticker-active.svg";
import Text from "../assets/icons/text.svg";
import TextActive from "../assets/icons/text-active.svg";
import Stamp from "../assets/icons/stamp.png";
import Envelope from "../assets/icons/envelope.png";
import Liner from "../assets/icons/open-envelope.png";

const Toolbar = () => {
  const dispatch = useDispatch();
  const { target, tool, selectedItemId } = useSelector((state) => state.editor);
  const tools = [
    // {
    //   id: "backdrop",
    //   label: "Backdrop",
    //   icon: Image,
    //   active: BackActive,
    // },
    {
      id: "text",
      label: "Add Text",
      icon: Text,
      active: TextActive,
      type: "card",
      action: () => {
        if(!selectedItemId) {
          const font = {
            family: "Ubuntu",
            size: 60,
            style: "normal",
            align: "left",
            weight: "normal",
          };
          const key = new Date().getTime();
          const canvas = document.getElementById("card-canvas");
          const ctx = canvas.getContext("2d");
          ctx.font = `${font.weight} ${font.style} ${font.size}px ${font.family}`;
          ctx.textAlign = font.align
          const width = ctx.measureText(INIT_TEXT).width;
          dispatch(
              updateDesign({
                action: "create",
                target: "card",
                type: "texts",
                data: {
                  id: key,
                  text: INIT_TEXT,
                  x: (canvas.width - width) / 2,
                  y: 100,
                  width,
                  height: 100,
                  angle: 0,
                  font,
                  color: '#000',
                },
              })
          );
          dispatch(setSelectedItemId(key));
        }
      },
    },
    {
      id: "sticker",
      label: "Stickers",
      icon: Sticker,
      active: StickerActive,
      type: "card",
    },
    {
      id: "envelope",
      label: "Envelope",
      icon: Envelope,
      type: "envelope",
    },
    // {
    //   id: "liner",
    //   label: "Liner",
    //   icon: Liner,
    //   type: "envelope",
    // },
    {
      id: "stamp",
      label: "Stamp",
      icon: Stamp,
      type: "envelope",
    },
  ];

  const handleClick = (tool) => {
    dispatch(setSelectedTool(tool));
    if (tool.action) tool.action();
  };

  return (
    <div className="w-24 h-screen flex flex-col items-center justify-center bg-white border-r border-r-gray-100">
      {tools.map((item) => (
        <Fragment key={item.id}>
          {(!item.type || item.type === target) && (
            <button
              className={`w-full flex flex-col items-center py-3 opacity-100 ${
                item.id === tool.id
                  ? `text-[#005ac8]`
                  : `text-black bg-white`
              }`}
              onClick={() => handleClick(item)}
            >
              <img
                src={item.id === tool.id ? item.active ?? item.icon: item.icon}
                alt={item.id}
                className={`w-6 `}
              />
              <span className={"text-xs"}>{item.label}</span>
            </button>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Toolbar;
