import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {deleteText} from "../../store/actions/Editor";
import Trash from "../../assets/icons/bin.png";

const Text = () => {
    const dispatch = useDispatch();
    const {selectedItemId} = useSelector((state) => state.editor);

    const handleDeleteText = () => {
        dispatch(deleteText(selectedItemId));
    };


    return (
        <div className="bg-white rounded-full w-14 py-2">
            <button
                className="w-14 h-14 flex items-center justify-center"
                title="Delete sticker"
                onClick={handleDeleteText}
            >
                <img src={Trash} alt=":( Not Found" className="w-5"/>
            </button>
        </div>
    );
};

export default Text;
