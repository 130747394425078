import {
    DELETE_STICKER,
    DELETE_Text,
    SET_BACKDROP,
    SET_ENVELOPE,
    SET_HOVERED_ITEM_ID,
    SET_LINER,
    SET_SELECTED_ITEM_ID,
    SET_SELECTED_TOOL,
    SET_STAMP,
    SET_TARGET,
    UPDATE_DESIGN,
    UPDATE_TEXT,
} from "../type";

const initialState = {
    target: "card",
    tool: {id: "text", label: "Add Text"},
    backdrop: "1.jpg",
    draw: {
        card: {
            stickers: [],
            texts: [{
                id: 1707816621139,
                text: 'Simple Text',
                x: 76.60003662109375,
                y: 55,
                width: 397.39996337890625,
                height: 125.44193663362522,
                angle: 0,
                font: {
                    family: 'Ubuntu',
                    size: 60,
                    style: 'normal',
                    weight: 'normal'
                },
                color: '#000'
            }],
        },
        envelope: [],
    },
    selectedItemId: null,
    hoveredItemId: null,
    envelope: "#6c341f",
    liner: "8.png",
    stamp: "1.png",
};

const EditorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_TOOL:
            return {...state, tool: action.payload};
        case SET_BACKDROP:
            return {...state, backdrop: action.payload};
        case SET_LINER:
            return {...state, liner: action.payload};
        case SET_STAMP:
            return {...state, stamp: action.payload};
        case SET_TARGET:
            return {...state, target: action.payload};
        case SET_HOVERED_ITEM_ID:
            return {...state, hoveredItemId: action.payload};
        case SET_SELECTED_ITEM_ID:
            return {...state, selectedItemId: action.payload};
        case UPDATE_DESIGN:
            const {action: flag, data, target, type} = action.payload;
            let cloneArr = state.draw[target][type];
            if (flag === "create") {
                cloneArr.push(data);
            } else {
                let selectedIndex = 0;
                cloneArr.map((item, i) => {
                    if (item.id === data.id) selectedIndex = i;
                });
                cloneArr.splice(selectedIndex, 1, data);
            }
            return {
                ...state,
                draw: {
                    ...state.draw,
                    [target]: {...state.draw[target], [type]: cloneArr},
                },
            };

        case UPDATE_TEXT:
            let selectedElement = action.payload.selectedId;
            let cloneText = state.draw.card.texts;
            let selectedTextsIndex = 0;
            cloneText.map((item, i) => {
                if (item.id === selectedElement) selectedTextsIndex = i;
            });
            cloneText.splice(selectedTextsIndex, 1);
            cloneText = [...cloneText, action.payload.item]

            return {
                ...state,
                draw: {
                    ...state.draw,
                    card: {...state.draw.card, texts: cloneText},
                },
            };
        case DELETE_STICKER:
            let cloneStickers = state.draw.card.stickers;
            let selectedIndex = 0;
            cloneStickers.map((item, i) => {
                if (item.id === action.payload) selectedIndex = i;
            });
            cloneStickers.splice(selectedIndex, 1);
            return {
                ...state,
                draw: {
                    ...state.draw,
                    card: {...state.draw.card, stickers: cloneStickers},
                },
            };
        case DELETE_Text:
            let cloneTexts = state.draw.card.texts;
            let selectedTextIndex = 0;
            cloneTexts.map((item, i) => {
                if (item.id === action.payload) selectedTextIndex = i;
            });
            cloneTexts.splice(selectedTextIndex, 1);
            return {
                ...state,
                draw: {
                    ...state.draw,
                    card: {...state.draw.card, texts: cloneTexts},
                },
            };
        case SET_ENVELOPE:
            return {...state, envelope: action.payload};
        default:
            return state;
    }
};

export default EditorReducer;
